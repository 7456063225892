@font-face {
    font-family: 'Ubuntu Mono';
    src: url("./../fonts/Ubuntu_Mono/UbuntuMono-Regular.ttf")
}

.ubuntu-mono {
    font-family: 'Ubuntu Mono', 'Open Sans';
}

@font-face {
    font-family: 'Roboto';
    src: url("./../fonts/Roboto/Roboto-Regular.ttf")
}

.roboto {
    font-family: 'Roboto', 'Open Sans';
}