// GLOBAL PARTS STYLING
@import './fonts.scss';

.hide-above-1512 {
    @media only screen and (width >= 1512px) {
        display: none;
    }
}

.hide-below-1512 {
    @media only screen and (width < 1512px) {
        display: none;
    }
}

.parts-list-row__row-container,
.app-slider {
    * {
        font-family: 'Ubuntu Mono', 'Open Sans'; //? affects tooltips etc.
    }
}

// mat dropdown overlay
.parts-status-dropdown {
    .dropdown {
        position: relative;
        &__label,
        &__contents {
            background-color: map-get($map: $autoflow-yellow, $key: 200) !important;
            padding-top: 3px;
            padding-bottom: 3px;
            padding-left: 3px;
            padding-right: 3px;
            width: 90px;
            min-width: 90px !important;
            box-sizing: border-box;
            white-space: nowrap;
            border-color: transparent !important;
            border-radius: 2px !important;
            &--wide {
                width: 160px !important;
            }
        }
        &__contents {
            position: absolute;
            z-index: 10;
            width: 100%;
            top: 100%;
            margin-top: 0px !important;
            gap: 4px !important;
            button {
                border-radius: 2px !important;
                text-align: center !important;
                &:not(:last-child) {
                    margin-bottom: 6px;
                }
                &:nth-child(1) {
                    background-color: map-get($map: $autoflow-secondary, $key: 900) !important;
                    color: map-deep-get($autoflow-secondary, contrast, 900) !important;
                }
                &:nth-child(2) {
                    background-color: map-get($map: $autoflow-secondary, $key: 900) !important;
                    color: map-get($map: $autoflow-yellow, $key: 200) !important;
                }
                &:nth-child(3) {
                    background-color: map-get($map: $autoflow-white, $key: 50) !important;
                    color: map-deep-get($autoflow-white, contrast, 50) !important;
                }
            }
        }
        &__label {
            &-placeholder {
                padding: 0px 8px 0px 0px !important;
                border-right: 1px solid;
                box-sizing: border-box;
            }
            > div {
                gap: 4px !important;
            }
        }

    }
}

.parts-list__app {
    > lib-dropdown {
        .dropdown {
            position: relative;
            &__contents {
                margin-top: 6px;
                position: absolute;
                z-index: 10;
                top: 100%;
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}

.parts-list-row-app {
    > lib-dropdown {
        .dropdown {
            position: relative;
            &__contents {
                margin-top: 6px;
                position: absolute;
                z-index: 10;
                top: 100%;
                width: fit-content;
                box-sizing: border-box;
                white-space: nowrap;
            }
            &__chevron {
                display: none;
            }
        }
    }
}
.parts-list-row__item {
    > lib-dropdown {
        .dropdown {
            &__label {
              padding: 0px !important;
            }
        }
    }
}
// part groups
.part-group {
    &__parts {
        .parts-list-row__part-num {
            min-width: 100px !important;
        }
    }
}

.part-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
    background-color: map-get($autoflow-secondary, 200);
    border: 1px dashed map-get($autoflow-secondary, 400);
    padding: 4px;
    border-radius: 2px;
    &__parts {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    &__part {
        display: inline-flex;
        flex-direction: row;
        margin-left: 15px;
        gap: 5px;
        &:not(:first-child) {
            app-parts-list-row,
            app-parts-list-row-added {
                margin-top: 6px;
            }
            .part-group {
                &__vertical-line {
                    padding-top: 6px;
                }
                &__horizontal-line {
                    margin-top: 30px;
                }
                &__connector-sq {
                    margin-top: 28px;
                }
            }
        }
        .part-group {
            &__removedTag {
                position: absolute;
                margin-left: 17px;
                margin-top: 4px;
                background-color: #fff;
                border: 1px solid #9c9c9c;
                border-radius: 2px;
                padding: 1px;
                font-weight: 700;
                font-size: 12px;
                color: #000;
                z-index: 99;
                line-height: 14px;
            }
        }
        app-parts-list-row,
        app-parts-list-row-added {
            flex: 1;
            width: 100%;
        }
    }
    &__connector {
        display: inline-flex;
        gap: 1px;
    }
    &__vertical-line {
        width: 1px;
        height: 100%;
        background-color: map-get($autoflow-secondary, 900);
        &--short {
            height: 50%;
        }
    }
    &__horizontal-line {
        height: 1px;
        width: 7px;
        background-color: map-get($autoflow-secondary, 900);
        margin-top: 24px;
    }
    &__connector-sq {
        height: 5px;
        width: 5px;
        background-color: map-get($autoflow-secondary, 900);
        margin-top: 22px;
    }
}

.parts-status-dropdown {
    display: inline-flex;
    &__ready-btn {
        background-color: map-get($map: $autoflow-yellow, $key: 200);
        padding: 0px 6px;
        font-size: 12px;
        border: 0px;
        cursor: pointer;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-color: transparent !important;
        white-space: nowrap;
        &:hover {
            background-color: map-get($map: $autoflow-yellow, $key: 400) !important;
        }
    }
    .dropdown {
        height: 100%;
        &__label {
            height: 100%;
            min-width: 0px !important;
            width: 40px !important;
            display: inline-flex;
            justify-content: center;
            padding: 0 5px !important;
            &-placeholder
            {
              padding: 6px 8px !important;
            }
            &:hover {
                ion-icon {
                    background-color: map-get($map: $autoflow-yellow, $key: 400) !important;
                }
            }
            > div {
                border-left: 1px solid #000;
            }
            ion-icon {
                margin-left: 5px;
            }
        }
        &__label-placeholder {
            display: none !important;
        }
    }
}

.order-details {
    &__non-ordered-parts {
        .mat-expansion-panel-body {
            padding: 0px !important;
        }
    }
}
.undo-all {
    &__icon {
        ion-icon {
            svg {
                height: 60%;
                width: 60%;
                margin-left: 20%;
                margin-top: 20%;
            }
        }
    }
}