@import './colours.scss';


.datePicker-custom{

        & .mat-mdc-icon-button .mat-mdc-button-persistent-ripple{
            margin: 5px;
        }

}
// ALL mat buttons
.mat-mdc-button-base {
    font-weight: 600; // all mat buttons will look semi-bold
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(#ffffff, 0)!important; //override backdrop colour to allow click
}

// ########### Mat-menu (no colour theme so outside loop) START ###########
/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu {
    padding:0 1rem;
    &.mat-mdc-menu-panel{
        border: 1px solid $border-colour;
        border-radius: $border-radius;
        box-shadow: 0 6px 16px grey;
    }
    & a {
        line-height: 3rem;
        width: 100%;
        color: $text-colour-grey;
        text-decoration: none;
        box-sizing: border-box;
        border-bottom: 1px solid $border-colour;
        &:hover{
            border-radius: $border-radius;
            background-color: $background-grey;
            border-bottom: 1px solid $background-grey;
        }
    }
}

// ########### Mat-Menu END ###########
// ########### Paginator START ###########
.mat-mdc-paginator__modern {
    // page size options
    background: none !important;
    max-width: 3840px;
    margin: 0 auto;

    .mat-mdc-paginator-outer-container {
        margin-top: 1rem;
    }

    .mat-mdc-paginator-page-size {
        display: none;
    }

    // x of y label
    .mat-mdc-paginator-range-label {
        order: 3;
        color: #000;
        font-weight: 600;
    }

    button.mat-mdc-tooltip-trigger {
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &.mat-button-disabled {
            opacity: 0.5;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:not(.mat-button-disabled) {
            &:hover {
                .mat-mdc-paginator-icon {
                    background-color: $button-colour;

                    path {
                        fill: #fff;
                    }
                }
            }
        }

        .mat-mdc-paginator-icon {
            border-radius: 0.2rem;
            font-weight: 800;
            width: 24px;
            background-color: #e3e4ea;
            padding: 0.5rem 0.1rem 0.5rem 0.1rem;
            transition: background-color .2s ease-in-out;

            path {
                fill: #000;
                transition: fill .2s ease-in-out;
            }
        }
    }

    .mat-mdc-paginator-navigation-first {
        order: 1;
    }

    .mat-mdc-paginator-navigation-previous {
        order: 2;
    }

    .mat-mdc-paginator-navigation-next {
        order: 4;
    }

    .mat-mdc-paginator-navigation-last {
        order: 5;
    }
}
// ########### Paginator END ###########

// ########### Mat-tooltip START ###########
.mat-mdc-tooltip {
    background-color: $text-colour-grey!important;
    user-select: none;
    }
// ########### Mat-tooltip END ###########

@each $colour,
$i in $colours {

    // ########### Mat-Flat-Button START ###########
    .mat-mdc-unelevated-button__#{$colour} {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        padding: 0 20px;


        &.mat-mdc-unelevated-button {
            &:not(:disabled) {
                background-color: map-get($i, $key:500);
                color: map-deep-get($i, contrast, 500);
                border: 1px solid map-get($i, $key:500);
            }
            height: auto !important;
            line-height: 2rem;
            padding: 6px 25px;
        }

        &--inactive{
            &.mat-mdc-unelevated-button {
                &:not(:disabled) {
                    background-color: map-get($i, $key:50);
                    color: map-get($i, $key:500);
                    border: 1px solid map-get($i, $key:50);
                }
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:hover:not(.mat-button-disabled) {
            background-color: map-get($i, $key:800);
            color: map-get($i, contrast, 500);
        }
        &:disabled{
            border-color: $border-colour;
        }
    }

    // ########### Mat-Flat-Button END ###########

    // ########### Mat-Flat-Small-Button START ###########
    .mat-mdc-unelevated-button__#{$colour}-small {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        padding: 0 20px;

        &.mat-mdc-unelevated-button {
            line-height: 1.9rem;
            padding: 4px 15px;
            background-color: map-get($i, $key:500);
            color: map-deep-get($i, contrast, 500);
            border: 1px solid map-get($i, $key:500);
            font-size: 0.75rem;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:hover:not(.mat-button-disabled) {
            background-color: map-get($i, $key:800);
            color: map-get($i, contrast, 500);
        }
        &:disabled{
            border-color: $border-colour;
        }
    }

    // ########### Mat-Flat-Small-Button END ###########

    // ########### Mat-Flat-Large-Button START ###########
    .mat-mdc-unelevated-button__#{$colour}-large {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        padding: 0 20px;

        &.mat-mdc-unelevated-button {
            line-height: 2.1rem;
            padding: 9px 45px;
            background-color: map-get($i, $key:500);
            color: map-deep-get($i, contrast, 500);
            border: 1px solid map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:hover:not(.mat-button-disabled) {
            background-color: map-get($i, $key:800);
            color: map-get($i, contrast, 500);
        }
        &:disabled{
            border-color: $border-colour;
        }
    }

    // ########### Mat-Flat-Large-Button END ###########

    // ########### Mat-Button START ###########
    .mat-mdc-button__#{$colour} {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        background-image: linear-gradient(map-get($i, $key:500), map-get($i, $key:500)) !important;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &.mat-mdc-button:not(.mat-button-disabled) {
            padding: 6px 25px;
            border-color: map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:hover:not(.mat-button-disabled) {
            color: map-get($i, contrast, 500);
        }

        &.mat-mdc-button {
            line-height: 2rem;
            // transitions on hover
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size .2s ease, color .2s ease;
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            &:hover:not(.mat-button-disabled) {
                background-size: 100% 100%;
            }
        }
        &:disabled{
            border-color: $border-colour;
        }
    }

    // ########### Mat-Button END ###########

    // ########### Mat-Small-Button START ###########
    .mat-mdc-button__#{$colour}-small {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        background-image: linear-gradient(map-get($i, $key:500), map-get($i, $key:500)) !important;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &.mat-mdc-button:not(.mat-button-disabled) {
            padding: 6px 15px;
            border-color: map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:hover:not(.mat-button-disabled) {
            color: map-get($i, contrast, 500);
        }

        &.mat-mdc-button {
            line-height: 1.9rem;
            // transitions on hover
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size .2s ease, color .2s ease;
            font-size: 0.75rem;

            &:hover {
                background-size: 100% 100%;
            }
        }
        &:disabled{
            border-color: $border-colour;
        }
    }

    // ########### Mat-Large-Button END ###########

    // ########### Mat-Large-Button START ###########
    .mat-mdc-button__#{$colour}-large {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        background-image: linear-gradient(map-get($i, $key:500), map-get($i, $key:500)) !important;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &.mat-mdc-button:not(.mat-button-disabled) {
            padding: 9px 45px;
            border-color: map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:hover:not(.mat-button-disabled) {
            color: map-get($i, contrast, 500);
        }

        &.mat-mdc-button {
            line-height: 2.1rem;
            // transitions on hover
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size .2s ease, color .2s ease;
            font-size: 0.75rem;
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            &:hover:not(.mat-button-disabled) {
                background-size: 100% 100%;
            }
        }
        &:disabled{
            border-color: $border-colour;
        }
    }

    // ########### Mat-Small-Button END ###########

    // ########### Mat-stroked-Button START ###########
    .mat-mdc-outlined-button__#{$colour} {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        background-image: linear-gradient(map-get($i, $key:500), map-get($i, $key:500)) !important;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &.mat-mdc-outlined-button:not(.mat-button-disabled) {
            padding: 6px 25px;
            border-color: map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:hover:not(.mat-button-disabled) {
            color: map-get($i, contrast, 500);
        }

        &.mat-mdc-outlined-button {
            line-height: 2rem;
            // transitions on hover
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size .2s ease, color .2s ease;
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            &:hover:not(.mat-button-disabled) {
                background-size: 100% 100%;
            }
        }
        &:disabled{
            border-color: $border-colour;
        }
    }

    // ########### Mat-stroked-Button END ###########

    // ########### Mat-stroked-small-Button START ###########
    .mat-mdc-outlined-button__#{$colour}-small {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        background-image: linear-gradient(map-get($i, $key:500), map-get($i, $key:500)) !important;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &.mat-mdc-outlined-button:not(.mat-button-disabled) {
            padding: 4px 15px;
            border-color: map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:hover:not(.mat-button-disabled) {
            color: map-get($i, contrast, 500);
        }

        &.mat-mdc-outlined-button {
            line-height: 1.9rem;
            // transitions on hover
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size .2s ease, color .2s ease;
            font-size: 0.75rem;
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            &:hover:not(.mat-button-disabled) {
                background-size: 100% 100%;
            }
        }
        &:disabled{
            border-color: $border-colour;
        }
    }

    // ########### Mat-stroked-small-Button END ###########

    // ########### Mat-stroked-large-Button START ###########
    .mat-mdc-outlined-button__#{$colour}-large {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        background-image: linear-gradient(map-get($i, $key:500), map-get($i, $key:500)) !important;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &.mat-mdc-outlined-button:not(.mat-button-disabled) {
            padding: 9px 45px;
            border-color: map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &:hover:not(.mat-button-disabled) {
            color: map-get($i, contrast, 500);
        }

        &.mat-mdc-outlined-button {
            line-height: 2.1rem;
            // transitions on hover
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size .2s ease, color .2s ease;
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            &:hover:not(.mat-button-disabled) {
                background-size: 100% 100%;
            }
        }
        &:disabled{
            border-color: $border-colour;
        }
    }

    // ########### Mat-stroked-large-Button END ###########

    .mat-mdc-radio-group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    // ########### Mat-Radio-Button START ###########
    .mat-mdc-radio-button__#{$colour} {
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-radio-disabled{
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            &.mat-mdc-radio-button.mat-accent.mat-radio-checked .mdc-form-field {
                border: 1px solid $border-colour;
                filter: drop-shadow(0 0.2rem 0.04rem $border-colour, 0.1);
            }
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            &.mat-mdc-radio-button.mat-accent.mat-radio-checked .mdc-radio__outer-circle {
                border-width: 3px;
                border-color: $border-colour;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            &.mat-mdc-radio-button.mat-accent.mat-radio-checked .mdc-radio__inner-circle {
                background-color: $border-colour;
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        label {
            font-weight: bold;
            font-size: 0.75rem;
            width: auto;
        }
        .mdc-form-field {
            padding-right: 0.5rem;
            border-radius: 4px;
        }
        .mdc-form-field {
            border: 1px solid $border-colour;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-mdc-radio-button.mat-accent.mat-radio-checked .mdc-form-field {
            border: 1px solid map-get($i, $key:500);
            filter: drop-shadow(0 0.2rem 0.04rem rgba(map-get($i, $key:500), 0.1));
        }
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        & .mat-radio__outer-circle {
            border-color: map-get($i, $key:500);
            border-width: 1px;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-mdc-radio-button.mat-accent.mat-radio-checked .mdc-radio__outer-circle {
            border-width: 3px;
            border-color: map-get($i, $key:500) !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-mdc-radio-button.mat-accent.mat-radio-checked .mdc-radio__inner-circle {
            border-color: map-get($i, $key:500) !important;
        }

        &--large {
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mdc-form-field {
                border: none;
                padding: 0.7rem 1.6rem 0.7rem 0.5rem;
                background-color: none;
                font-weight: bold;
                font-size: 0.75rem;
                width: auto;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            & .mat-radio-container {
                width: 25px;
                height: 25px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            &.mat-mdc-radio-button.mat-accent.mat-radio-checked .mdc-form-field {
                border: none;
                filter: none;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            & .mdc-radio__outer-circle {
                border-color: map-get($i, $key:500);
                border-width: 1px;
                height: 25px;
                width: 25px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            & .mdc-radio__inner-circle {
                height: 25px;
                width: 25px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            &.mat-mdc-radio-button.mat-accent.mat-radio-checked .mdc-radio__outer-circle {
                border-width: 4px;
                border-color: map-get($i, $key:500);
            }
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            &.mat-mdc-radio-button.mat-accent.mat-radio-checked .mdc-radio__inner-circle {
                background-color: map-get($i, $key:500);
            }
        }

        .mat-mdc-radio-button {
            display: flex;
        }

        // ########### Mat-Radio-Button END ###########
    }

    // ########### Mat-Radio-Button END ###########

    // ########### Mat-checkbox START ###########
    .mat-mdc-checkbox__#{$colour} {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &.mat-checkbox-disabled{
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mdc-checkbox__background {
                background-color: $border-colour !important;
            }
        }
        & .mat-mdc-checkbox .mat-ripple {
            background-color: map-get($i, $key:500) !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        & .mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple {
            background-color: map-get($i, $key:500)!important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        & .mat-checkbox-inner-container {
            height: 25px;
            width: 25px;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        & .mat-checkbox-frame {
            border-color: map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &.mat-mdc-checkbox-checked .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
            background-color: map-get($i, $key:500) !important;
            border-color:  map-get($i, $key:500) !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mdc-checkbox__checkmark {
            color: map-get($i, contrast, 500) !important;
        }
    }

    // ########### Mat-checkbox END ###########
    // ########### Mat-checkbox-small START ###########
    .mat-mdc-checkbox__#{$colour}-small {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &.mat-checkbox-disabled{
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
                background-color: $border-colour !important;
            }
        }
        & .mat-mdc-checkbox .mat-ripple-element {
            background-color: map-get($i, $key:500)!important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        & .mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple{
            background-color: map-get($i, $key:500)!important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        & .mat-checkbox-frame {
            border-color: map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
            background-color: map-get($i, $key:500) !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        & .mat-checkbox-checkmark-path {
            stroke: map-get($i, contrast, 500) !important;
        }
    }

    // ########### Mat-checkbox-small END ###########
    // ########### Mat-checkbox-small-border START ###########
    .mat-mdc-checkbox__#{$colour}-small-border {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &.mat-checkbox-disabled{
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
                background-color: $border-colour !important;
            }
            &.mat-mdc-checkbox {
                padding: 0.5rem;
                border-radius: $border-radius;
                border: 1px solid $border-colour;
                /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                &.mat-checkbox-checked{
                    border: 1px solid $border-colour;
                    box-shadow: 0 0.2rem 0.04rem rgba($border-colour, 0.1);
                }
            }
        }
        & .mat-mdc-checkbox .mat-ripple-element {
            background-color: map-get($i, $key:500)!important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        & .mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple{
            background-color: map-get($i, $key:500)!important;
        }
        &.mat-mdc-checkbox {
            padding: 0.5rem;
            border-radius: $border-radius;
            border: 1px solid $border-colour;
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &.mat-checkbox-checked{
                border: 1px solid map-get($i, $key:500);
                box-shadow: 0 0.2rem 0.04rem rgba(map-get($i, $key:500), 0.1);
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        & .mat-checkbox-frame {
            border-color: map-get($i, $key:500);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        &.mat-checkbox-checked .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
            background-color: map-get($i, $key:500) !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        & .mat-checkbox-checkmark-path {
            stroke: map-get($i, contrast, 500) !important;
        }
    }

    // ########### Mat-checkbox-small-border END ###########
    // ########### Mat-Toggle-Button START ###########
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle__#{$colour} {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        &.mat-button-toggle-group-appearance-standard {
            border: 1px solid map-get($i, $key:500);
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            & .mat-button-toggle-label-content {
                padding: 0 25px;
                line-height: 46px;
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        & .mat-button-toggle-checked {
            background-color: map-get($i, $key:500);
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            &.mat-button-toggle-appearance-standard {
                color: map-get($i, contrast, 500);
            }
        }
    }

    // ########### Mat-Toggle-Button END ###########

    // ########### Mat-expansion-panel START ###########
    .mat-expansion-panel__#{$colour} {

        & mat-panel-description {
            font-size: 0.85rem;
        }

        & .mat-expansion-panel-header-title {
            font-weight: bold;
        }

        &.mat-expansion-panel:not([class*=mat-elevation-z]) {
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 0), 0px 2px 2px 0px rgb(0 0 0 / 0), 0px 1px 5px 0px rgb(0 0 0 / 0);
            border: 1px solid $border-colour;

            &.mat-expanded {
                border: 1px solid map-get($i, $key:500);
            }
        }
    }

    .mat-expansion-panel__ghost {
        & .mat-expansion-panel-header-title {
            font-weight: bold;
        }

        &.mat-expansion-panel:not([class*=mat-elevation-z]) {
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 0), 0px 2px 2px 0px rgb(0 0 0 / 0), 0px 1px 5px 0px rgb(0 0 0 / 0);
            background: none;
        }

        & .mat-content {
            flex-direction: column;
        }

        & mat-panel-description {
            font-size: 0.85rem;
        }

        & .mat-expansion-panel-header {
            flex-direction: row-reverse;
        }

        & .mat-expansion-panel-header-title,
        & .mat-expansion-panel-header-description {
            margin-left: 28px;
            margin-right: 0;
        }

        &.mat-expansion-panel-body {
            padding: 0 24px 16px 57px;
        }

        &.mat-expanded {
            & .mat-expansion-indicator {
                &::after {
                    transform: rotate(-135deg) !important;
                }
            }
        }

        & .mat-expansion-indicator {

            transform: rotate(90deg);

            &::after {
                color: $button-colour;
                transform: rotate(-45deg);
            }
        }
    }
    // ########### Mat-expansion-panel END ###########


    // ########### Mat-divider START ###########
    .mat-divider__#{$colour} {
        &.mat-divider {
            border-top-color: map-get($i, $key:500);
        }
    }
    // ########### Mat-divider END ###########

    // ########### Mat-ripple START ###########
    .mat-ripple__#{$colour} {
        & .mat-ripple-element {
            background-color: rgba(map-get($i, $key:500), 0.3);
        }
    }
    // ########### Mat-ripple END ###########

    // ########### Mat table with a fixed header in product list START ###########

    .mat-mdc-table__product-list {
        width: 100%;
        min-width: 1200px;
        table-layout: fixed;

        .mat-mdc-cell {
            padding: 0;
        }

        // table header column widths
        th {
            &.mat-sort-header,
            &.mat-header {
                &__scheme {
                    width: 92px;
                }

                &__orderRef,
                &__partCount {
                    width: 100px;
                }
                &__status {
                    width: 100px;
                }

                &__delayedParts,
                &__net,
                &__orderRef
                {
                    width: 120px;
                }

                &__orderDate {
                    width: 125px;
                }

                &__details,
                &__link {
                    width: 150px;
                }
                &__quoteNumber {
                    width: 180px;
                }
               
            }
        }
        th,
        td {
            box-sizing: border-box;
            max-width: 150px;
        }
        &--fixed-header {
            padding-top: 62px;
            thead {
                position: fixed;
                background: #fff !important;
                box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.05);
                top: 12rem;
                left: 0;
                z-index: 10;
                table-layout: fixed;
                display: table;
                @media only screen and (max-width: 950px) {
                    top: 12rem;
                }
                @media only screen and (max-width: 755px) {
                    top: 13rem;
                }
            }
            tbody {
                table-layout: fixed;
                display: table;
                // table body column widths
                td {
                    &.mat-column {
                        &-scheme {
                            width: 92px;
                        }

                        &-orderRef,
                        &-status,
                        &-partCount {
                            width: 100px;
                        }

                        &-delayedParts,
                        &-net,
                        &-orderRef {
                            width: 120px;
                        }

                        &-orderDate {
                            width: 125px;
                        }

                        &-details,
                        &-link {
                            width: 150px;
                        }
                    }
                }
            }
        }
        thead {
            min-width: 1000px;
            > tr {
                position: relative;
                width: 100%;
            }
        }
        thead, tbody, tr {
            width: 100%;
        }

        tbody {
            overflow: hidden;
            border-spacing: 0 .25rem;
        }
    }
    // ########### Mat table with a fixed header in product list END ###########

    // ########### Parts List mat table START ###########

    .mat-mdc-table__parts-list {
        width: 100%;
        min-width: 1050px;
        &--fixed-header {
            padding-top: 80px !important; // reduces jitter when transitioning from and to fixed upon scroll
            thead {
                position: fixed;
                display: table;
                background: #fff !important;
                box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.05);
                min-width: 1050px;
                top: 7.8rem;
                z-index: 10;
                left: 0;
                border-right: 1.4rem solid white;
                border-left: 1.4rem solid white;
            }
        }
        thead {
            > tr {
                width: 100%;
                .mat-sort-header {
                    &__docs {
                        width: 50px;
                    }
                    &__item {
                        width: 2rem;
                    }
                    &__partId {
                        width: 170px;
                    }
                    &__description {
                        width: auto;
                    }
                    &__edit {
                        width: 35px;
                    }
                    &__quantity {
                        width: 100px;
                    }
                    &__rrp {
                        width: 100px;
                    }
                    &__surcharge {
                        width: 100px;
                    }
                    &__discount {
                        width: 100px;
                    }
                    &__net,
                    &__unit {
                        width: 140px;
                    }
                    &__remove {
                        width: 80px;
                    }
                }
                th {
                    box-sizing: border-box;
                }
            }
        }
        thead, tbody, tr {
            width: 100%;
        }
        tbody {
            overflow: hidden;
            border-spacing: 0 .25rem;
            .mat-column {
                &-docs {
                    width: 50px;
                }
                &-item {
                    min-width: 2rem;
                    width: 2rem;
                }
                &-partId {
                    min-width: 170px;
                    width: 170px;
                }
                &-description {
                    width: auto;
                }
                &-edit {
                    width: 35px;
                }
                &-quantity {
                    width: 100px;
                }
                &-rrp {
                    width: 100px;
                }
                &-surcharge {
                    width: 100px;
                }
                &-discount {
                    width: 100px;
                }
                &-net,
                &-unit {
                    width: 140px;
                }
                &-remove {
                    width: 80px;
                }
            }
            td {
                box-sizing: border-box;
            }
        }
    }
    // ########### Parts List mat table END ###########

    // ########### Matt Slide Toggle START #############
        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        .mat-slide-toggle-thumb {
            background-color: #000 !important;
        } 
        // ########### Matt Slide Toggle END #############

    // ########### Mat Dialog Container START #############
    .mat-mdc-dialog-container {
        .mdc-dialog__container {
            .mat-mdc-dialog-surface {
                padding: 24px;
                width: 100%;
                height: 100%;
                min-height: inherit;
                max-height: inherit;
                border-radius: 4px;
            }
            .mat-mdc-dialog-title {
                padding: 0px;
                margin: 0px;
            }
        }
    }
    // ########### Mat Dialog Container END #############
}

// ########## Mat Dialog Content START ##############
.mdc-dialog {
    .mat-mdc-dialog-content.mdc-dialog__content {
        padding: 0px;
    }
}
// ########## Mat Dialog Content END ################

// ########## Mat Form Field START ##################
.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
        &.mdc-text-field--outlined {
            .mat-mdc-form-field-infix {
                padding-top: 10px;
                padding-bottom: 8px;
                min-height: 0px;
            }
        }
    }
    .mdc-notched-outline__notch {
        width: fit-content !important;
    }
    .mdc-floating-label {
        padding-bottom: 10px;
    }
}
// ########## Mat Form Field END ####################