.mat-mdc-snack-bar-container {
    position: relative;
}
.mat-snack-bar-container--info {
    background: #EAEEFC !important;
    outline-color: #266CDE;
}
.mat-snack-bar-container--success {
    background: #BAF8D7 !important;
    outline-color: #09915E;
}
.mat-snack-bar-container--error {
    background: #FFDDDD !important;
    outline-color: #D16666;
}
.mat-snack-bar-container--warn {
    background: #FDEEC9 !important;
    outline-color: #A77806;
}
.mat-snack-bar-container--outlined {
    box-shadow: initial !important;
    outline-width: 1px;
    outline-style: solid;
    outline-offset: -1px;
}