@import '../globals.scss';
@import './colours.scss';

// FIND BELOW ALL THE DIFFERENT TYPES OF INPUTS AS OUTLINED IN THE SYSTEM ADOBE XD FILE...

// ########### (General) Mat Form Field START ###########
.mat-form-field__modern,
.mat-mdc-form-field__modern {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-flex {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline-thick {
            color: $button-colour;
        }
    }
    .mat-mdc-form-field-infix {
        font-size: 12px;
        width: initial;
    }

    &.mat-focused {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-flex {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-mdc-form-field-outline-thick {
                color: #000;
            }
        }
    }
}

// ########### (General) Mat Form Field End ###########

// ########### (RRP) Mat Form Field START ###########

.mat-mdc-form-field__currency {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
        max-height: 2.35rem;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-prefix {
        font-weight: 600; // 'semi-bold'
        top: 0 !important;
        padding-right: .15rem;
        padding-left: 15%;
        color: grey;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
        margin-left: auto;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        font-weight: 600; // 'semi-bold'
        width: 4.5rem;
        padding: 0.1rem 0 .8rem 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        color: $border-colour;
        font-weight: 600;
        top: 0 !important;
        padding-left: 0.5rem;
    }

    // to give suffix / prefix a fixed width
    &--fixed-width-suffix {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-suffix {
            width: 1.5rem;
            max-width: 1.5rem;
        }
    }
}

// ########### (RRP) Mat Form Field End ###########

// ########### (Standard) Mat Form Field START ###########

.mat-form-field__standard,
.mat-mdc-form-field__standard {
    max-width: 16.25rem;
    width: 100%;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
        line-height: 1.34rem;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0 !important;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        font-size: 12px;
        width: initial !important;
        padding-top: .2rem !important;
        padding-bottom: .6rem !important;
    }
    &--error{
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-subscript-wrapper {
            top:calc(100% - 0.2rem);
        }
    }
}

.sidebar__invoice--content-input {
    .mat-form-field {
        &-wrapper {
            .mat-form-field-subscript-wrapper {
            div {
                mat-error {
                margin-top: 0.95em;
            }
            }     
        }   
    }
    }
}

// ########### (Description) Mat Form Field End ###########

// ########### (Description) Mat Form Field START ###########

.mat-mdc-form-field__description {
    min-width: 16.25rem;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        width: initial;
        padding-top: .2rem !important;
        padding-bottom: .6rem !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        font-size: 1.2rem;
        top: 0 !important;

        >button {
            justify-content: end !important;
            background: none !important;
            color: #000 !important;
        }

        ion-icon {
            fill: #07192C;
        }
    }
}

// ########### (Description) Mat Form Field End ###########

// ########### (Email) Mat Form Field START ###########

.mat-mdc-form-field__email {
    text-align: right !important;
    min-width: 16.25rem;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        width: initial;
        padding: .4rem 0 .8rem 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        top: 0 !important;
        color: grey;

    }
}

// ########### (Email) Mat Form Field End ###########

// ########### (Suffix) Mat Form Field START ###########

.mat-mdc-form-field__suffix {
    min-width: 5.6rem;
    text-align: right !important;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        color: grey;
        top: 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        padding-top: .6rem !important;
        padding-bottom: .8rem !important;
        padding-right: .5rem;
        padding-left: 2.2rem;
        width: initial;
    }
}

// ########### (Suffix) Mat Form Field End ###########

// ########### (Search) Mat Form Field START ###########

.mat-form-field__search,
.mat-mdc-form-field__search {
    min-width: 12rem;
    max-height: 2.5rem;

    &.mat-form-field-appearance-outline {

        .mdc-notched-outline {
            border-radius: 2rem;
            border: 1px solid $border-colour;
            //border: 1px solid #0000001f;
            max-height: 2.5rem;
        }

        .mdc-notched-outline__leading {
            border-radius: 2rem 0 0 2rem !important;
            min-width: 2rem !important;
            max-height: 2.5rem;
        }

        .mdc-notched-outline__trailing {
            border-radius: 0 2rem 2rem 0 !important;
            max-height: 2.5rem;
        }

        .mat-mdc-form-field-infix {
            padding: .5rem 0 .5rem 0 !important;
            font-size: 12px;
        }

    //     /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    //     .mat-mdc-form-field-icon-suffix {
    //         top: 0.4em
    //     }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    // .mat-form-field-wrapper {

    .mat-mdc-text-field-wrapper {
        padding: 0;
        background: #fff;
        padding-bottom: 0;
        border-radius: 2rem;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    // .mat-form-field-flex {

    .mat-mdc-form-field-flex {
        padding-left: .8rem !important;
        padding-right: .8rem !important;
        max-height: 2.5rem;
    }

    input.mat-mdc-input-element {
        line-height: 1.5rem;
        //caret-color: #266cde
    }

    ion-icon {
        font-size: 2rem;
        line-height: 0.625;
        position: relative;
        top: 1px;
        left: 6px;
        fill: #07192C;
    }
}

.mat-mdc-form-field__search_groups {
    min-width: 12rem;

    &.mat-form-field-appearance-outline {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline {
            border-radius: 6px;
            border: 1px solid $border-colour;
            background-color: #E3F2FC;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline-start {
            min-width: 2rem !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
            display: flex;
            padding: .2rem 0 .5rem 0 !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-suffix {
            top: 0.4em
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        padding: 0;
        background: #fff;
        padding-bottom: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
        padding-left: .8rem !important;
        padding-right: .8rem !important;
    }

    input.mat-mdc-input-element {
        line-height: 1.5rem;
    }

    ion-icon {
        font-size: 1rem;
        line-height: 0.625;
        position: relative;
        margin-right: 6px;
        top: 3px;
        fill: #07192C;
    }
}

// ########### (Search) Mat Form Field END ###########

// ########### (Quantity) Mat Form Field START ###########

.mat-mdc-form-field__quantity {
    @media print {
        & button {
            display: none;
        }
    }

    /* Chrome */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        line-height: 2.05rem;
        margin-top: -0.7rem;
        background-color: #0000001f;
        margin-bottom: -0.1rem;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
        display: flex;
        justify-content: center;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        padding: 0;
        max-width: 8rem;
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
            padding: 0.2rem 0 0 0;
            min-width: 2rem;
            max-width: 2rem;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
        align-items: stretch !important;
        overflow: hidden;
        text-align: center;
        padding: 0 0 2px 0 !important;
        font-weight: 600; // 'semi-bold'
        z-index: 0; // prevents children appearing over elements they shouldn't
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-outline {
        background-color: transparent !important;
        z-index: 1;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-prefix,
    .mat-form-field-suffix {
        overflow: hidden;
        position: relative;

        >button {
            min-width: 2rem !important;
            padding: 0;
            border-radius: 0;
            height: 100%;
            background-color: transparent;
            transition: background-color 600ms cubic-bezier(0.25, 0.8, 0.25, 1), color 600ms cubic-bezier(0.25, 0.8, 0.25, 1);

            ion-icon {
                --ionicon-stroke-width: 72px;
            }

            &:disabled {
                color: darken($border-colour, 20%);
            }

            &:not(:disabled) {
                &:hover {
                    background-color: $button-colour;
                    color: white;
                }
            }
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-prefix {
        border-radius: 5px 0 0 5px;
        overflow: hidden;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        border-radius: 0 5px 5px 0;
        overflow: hidden;
    }

    // rounded variant (used for side pane)
    &--rounded {
        margin: 0 .6rem;
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-flex {
            overflow: initial;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline {
            visibility: hidden;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-wrapper {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-infix {
                min-width: 3rem;
                max-width: 3rem;
            }
        }
        input[type="number"] {
            background-color: transparent;
            width: 1.2rem;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-wrapper {
            font-size: 1.2rem;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-prefix,
        .mat-form-field-suffix {
            overflow: initial;
            > button {
                border-radius: 100%;
                outline: 2px solid;
                background-color: transparent !important;
                &:not(:disabled) {
                    &:hover,
                    &:focus,
                    &:active {
                        outline-color: map-get($arcus-primary, $key:500);
                        > span {
                            color: map-get($arcus-primary, $key:500);
                        }
                    }
                }
            }
        }
    }
}

// ########### (Quantity) Mat Form Field END ###########

// ########### (Name) Mat Form Field START ###########

.mat-mdc-form-field__name {
    min-width: 16.25rem;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        width: initial;
        padding: .4rem .2rem .8rem .2rem !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-prefix {
        opacity: 0.3;
        font-size: 1.5rem;
    }
}

// ########### (Name) Mat Form Field END ###########

// ########### (Password) Mat Form Field START ###########

.mat-mdc-form-field__password {
    min-width: 17.5rem;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        width: initial;
        padding: .4rem .2rem .8rem .2rem !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix,
    .mat-form-field-prefix {
        opacity: 0.5;
        font-size: 1rem;
        top: .15rem !important;
    }

    .mat-mdc-form-field__password-visibility {
        background: 0 !important;
        color: #000 !important;
        border: 0;
        opacity: 0.5;
        cursor: pointer;
    }

    .mat-mdc-form-field__password-visibility--opaque {
        opacity: 1;
    }
}

// ########### (Password) Mat Form Field END ###########

// ########### (Dropdown) Mat Form Field START ###########

.mat-mdc-form-field.mat-form-field__dropdown,
.mat-mdc-form-field__dropdown {
    min-width: 11.25rem;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-text-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-infix {
        width: initial;
        padding: .6rem 0 !important;
        // padding: .4rem 0 .8rem 0 !important;
        min-height: 0px;
        .mat-mdc-select-trigger {
            font-size: 12px;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-mdc-select-arrow-wrapper {
        display: flex;
        align-items: center;
        // padding-top: 10px;
    }
    .mat-mdc-form-field-subscript-wrapper {
        height: 0px;
    }
}

// ########### (Dropdown) Mat Form Field END ###########

// ########### (Tagged) Mat Form Field START ###########

.mat-mdc-form-field__tagged {
    min-width: 19.375rem;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        width: initial;
        padding-top: .4rem !important;
        padding-bottom: .8rem !important;
    }

    [contentEditable] {
        &:focus {
            outline: 0px solid transparent;
        }
    }

    .mat-mdc-form-field__tagged-tag {
        background: #EFEAFC;
        color: #8866C9;
        font-weight: bold;
        border-radius: .25rem;
        padding: .4rem .2rem .4rem .2rem;
    }
}

// ########### (Tagged) Mat Form Field END ###########

// ########### (Address Search) Mat Form Field START ###########

.mat-mdc-form-field__address-search {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
        align-items: stretch !important;
        overflow: hidden;
        padding: 0 0 2px 0 !important;
        font-weight: 600; // 'semi-bold'
        z-index: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-outline {
        z-index: 1;
        background-color: transparent !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        padding: .8rem .6rem .8rem .6rem !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        overflow: hidden;
        position: relative;

        >button {
            min-width: 2rem !important;
            padding: 0 .6rem;
            border-radius: 0;
            height: 100%;
            background-color: $button-colour;
            color: white;
            font-size: 12px;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        border-radius: 0 5px 5px 0;
        overflow: hidden;
    }
}

// ########### (Address Search) Mat Form Field END ###########

// ########### (Parts Panel) Mat Form Field START ###########
.mat-mdc-form-field__parts-panel {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
        align-items: stretch !important;
        overflow: hidden;
        font-weight: 600; // 'semi-bold'
        padding-right: .25rem !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-prefix {
        line-height: 3.4rem;
        padding-right: .15rem;
        padding-left: 15%;
        color: grey;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        padding: .2rem 0 .4rem 0;

        >button {
            height: 100%;
            background-color: $button-colour;
            color: #fff;
            border: 0;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}

// ########### (Parts Panel) Mat Form Field End ###########


// ########### (Card Details) Mat Form Field START ###########

.mat-mdc-form-field__card-details {
    min-width: 16.25rem;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
        background: #fff;
        padding-bottom: 0;
        border-radius: $border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
        width: initial;
        padding-top: .4rem !important;
        padding-bottom: .8rem !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-suffix {
        top: .35rem !important;
    }
}

// ########### (Card Details) Mat Form Field End ###########

// ###########  Mat Datepicker START ###########

.mat-mdc-form-field__datepicker {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    & .mat-form-field-wrapper .mat-form-field-flex {
        display: flex;
        align-items: center;
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        & .mat-form-field-suffix {
            padding-bottom: 0.5rem;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline {
            background-color: #fff;
            border-radius: $border-radius;
        }
    }
    .mat-datepicker-toggle {
        .mat-mdc-icon-button {
            // USED TO ENSURE SIMILAR SIZE TO OTHER MAT INPUTS
            // SPECIFICAL SCENARIO SEE "INVOICE DIALOG" COMPONENT
            width: 36px;
            height: 36px;
            padding: 6px;
        }
    }
}

// ###########  Mat Datepicker END ###########

// ###########  Mat textarea START ###########

.mat-mdc-form-field__textarea {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    & .mat-form-field-wrapper .mat-form-field-flex {
        display: flex;
        align-items: center;
        border-radius: $border-radius;
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline {
            background-color: #fff;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
            padding: 0 0 1rem 0;
        }
    }
}

// ###########  Mat textarea END ###########

// ###########  Mat menu START ###########

.mat-mdc-form-field__menu {
    &.mat-mdc-menu-panel {
        & ion-icon {
            font-size: 1.6rem;
            margin-right: 1rem;
        }

        & .mat-mdc-menu-item {
            font-weight: bold;
            display: flex;
            align-items: center;
            position: relative;

            & .dash__underlay-icon,
            .group-dash__underlay-icon {
                position: absolute;
                left: 1.7rem;
                font-size: 0.6rem;
                top: 1.2rem;
            }

            & .dash__overlay-icon,
            .group-dash__overlay-icon {
                transform: scaleX(-1) rotate(90deg);
            }
        }
    }
}

// ###########  Mat menu END ###########


// STYLING BASED ON CLASS / COLOUR
@each $colour,
$i in $colours {
    .mat-mdc-form-field__#{$colour} {

        /*
            the outline colour on hover always changes based on the colour class applied.
        */
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-flex {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-outline-thick {
                color: map-get($i, $key:500);
            }
        }

        /*
            add classes here where you want the background/ text colour of any child buttons of the prexix /
            suffix class to be only be applied on hover.
        */
        &.mat-mdc-form-field__quantity {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-prefix,
            .mat-form-field-suffix {
                >button {
                    &:not(:disabled) {
                        &:hover {
                            background-color: map-get($i, $key:500);
                            color: map-deep-get($i, contrast, 500);
                        }
                    }

                    @media print {
                        display: none;
                    }
                }
            }
        }

        &--read-only {
            & input {color: $border-colour}
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-flex {
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-outline-thick {
                    color: $border-colour;
                }
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-outline-thick .mat-form-field-outline-start,
                .mat-form-field-outline-thick .mat-form-field-outline-end,
                .mat-form-field-outline-thick .mat-form-field-outline-gap {
                    border-width: 1px;
                    color: $border-colour;
                }
            }

        }

        /*
            add classes here where you want the background/ text colour of any child buttons of the prexix /
            suffix class to be always applied.
        */
        &.mat-mdc-form-field__address-search,
        &.mat-form-field__parts-panel {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-prefix,
            .mat-form-field-suffix {
                >button {
                    background-color: map-get($i, $key:500);
                    color: map-deep-get($i, contrast, 500);
                }
            }
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
:disabled .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    color: $border-colour;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
:disabled .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
:disabled .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
:disabled .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-outline {
    @media print {
        color: rgba($color: #000000, $alpha: 0) !important;
    }
}

// ------------------------------------
// END OF INPUTS ######################
// ------------------------------------