$column-gap: 16px;
$border-colour: #CCD3DC;
$background-grey: #F2F2F3;
$body-grey: #f6f6f6;
$border-radius: 4px;
$border-radius-large: 8px;
$text-colour-grey: #07192C;
$text-colour-light-grey: #778AA7;
$button-colour: #266CDE; //need to pull from theme colors when installed
$button-text-colour: #A7FAD4; //need to pull from theme colors when installed
$orange-colour: #F4D589; //need to pull from theme colors when installed
$gradient-blue: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, #CBEBF7 100%);
$gradient-blue-blue: linear-gradient(90deg, #59CDF8 50%, #2973E0 100%);
$gradient-purple: linear-gradient(90deg, #9E7FD8 50%, #471F93 100%);
$purple-colour: #8866C9;
$gradient-green: linear-gradient(90deg, #A7FAD4 50%, #68CAA2 100%);

$new-colour:#276AD6;
$part-fulfilled-colour:$text-colour-grey;
$in-progress-colour:#F4D589;
$fulfilled-colour:#23B171;
$accepted-colour:#FFBA08;
$additional-colour:#8866C9;
$negotiation-colour:#F4D589;
$fir-colour:#F72585;

$tabs-hover-grey: #DEDEDE;
$parts-list-grey: #F6F6F6;



$z-index-low:10;
$z-index-mid:55;
$z-index-high:99;

@mixin rounded {
    border-radius: $border-radius;
}

@mixin grey-border {
    border-color: $border-colour;
}

@mixin grey-border-top {
    border-top-color: $border-colour;
}

@mixin grid {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    gap: $column-gap;
    grid-auto-rows: min-content;
    margin-left: auto;
    margin-right: auto;

    // Desktop 1024px
    @media only screen and (1024px <= width < 1280px) {
        max-width: 992px;
    }

    // Desktop 1280px
    @media only screen and (1280px <= width < 1440px) {
        max-width: 1248px;
    }

    // Desktop 1440px
    @media only screen and (1440px <= width < 1512px) {
        max-width: 1246px;
    }

    // Desktop 1512px
    @media only screen and (1512px <= width < 1728px) {
        max-width: 1318px;
    }

    // Desktop 1728px
    @media only screen and (1728px <= width < 1900px) {
        max-width: 1322px;
    }

    // wide screen
    @media only screen and (width >= 1900px) {
        max-width: 1585px;
    }
}
@mixin table-grid {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-auto-rows: min-content;
}
@mixin table-grid-16 {
    display: grid;
    grid-template-columns: repeat(16, [col-start] 1fr);
    grid-auto-rows: min-content;
}

@mixin span-2 {
    grid-column: 10/span 2;
    grid-row: 2/span 10;
    @media only screen and (max-width: 1600px) {
        grid-column: 2/span 10;
        grid-row: 9;
    }
    @media only screen and (max-width: 1270px) {
        grid-column: 1/span 12;
        margin: 0 1rem;
    }
}

@mixin span-4 {
    grid-column: 20/span 4;
    grid-row: 3/span 10;
    margin-right: -$column-gap;
    @media only screen and (max-width: 1600px) {
        grid-column: 2/span 14;
        grid-row: 9;
        margin-right: 0;
    }
    @media only screen and (max-width: 1270px) {
        grid-column: 2/span 16;
        margin: 0;
    }
}

@mixin span-22 {
    grid-column: 2/span 22;
    @media only screen and (max-width: 1600px) {
        grid-column: 1/span 24;
        margin: 0 1rem;
    }
}

@mixin span-12 {
    grid-column: 1 / span 12;
}

@mixin span-15 {
    grid-column: 2/span 15;
    @media only screen and (max-width: 1270px) {
        grid-column: 1/span 16;
        margin: 0 1rem;
    }
}
@mixin span-18 {
    grid-column: 2/span 18;
    @media only screen and (max-width: 1600px) {
        grid-column: 2/span 22;
    }

    @media print {
      grid-column: 1/span 24;
    }
}


@mixin panel {
    border: 1px solid $border-colour;
    border-radius: $border-radius;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1.4rem;
    flex-wrap: wrap;
}

@mixin full-width {
    grid-column: col-start / span 12;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}
@mixin flex-column-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}
@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin anchor {
    font-size: 0.75rem;
    color: rgba($text-colour-grey, 0.65);
    text-decoration: none;
    cursor: pointer;
}

@mixin page-spacer {
    margin-top: 7.6rem;
    margin-bottom: 12rem;
}
/*
    used on '/orders' or '/quotes' pages
*/
@mixin page-spacer-all-quotes-orders {
    margin-top: 22rem;
    margin-bottom: 8rem;
    @media only screen and (max-width: 1600px) {
        margin-top: 23rem;
    }
    @media only screen and (max-width: 1150px) {
        margin-top: 24rem;
    }
    @media only screen and (max-width: 920px) {
        margin-top: 25rem;
    }
    @media only screen and (max-width: 680px) {
        margin-top: 26rem;
    }
}
@mixin error-page{
    .error-page {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        & img{
            width: 100%;
            max-width: 456px;
        }
        & h1{
            font-size: 2.75rem;
            color: $button-colour;
            font-weight: 900;
            margin:3.5rem 0 1.75rem 0;
        }
        & p{
            color: rgba($text-colour-grey, 0.55);
            font-size: 1.75rem;
            font-weight: 600;
            margin-bottom: 1.31rem;
        }

        &__buttons {
            margin-top: 3.06rem;
            display: flex;
            flex-direction: row;
            justify-content: center;

            & button, & a {
                margin: 1rem;
            }
        }
    }
}

@mixin h2 {
    font-size: 1rem;
    line-height: 1rem;
    margin:2.5rem 0 0.75rem 0;
    display: flex;
    align-items: center;
}

@page {
  size: A4;
}
