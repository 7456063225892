@use '../arcus-theme.scss' as theme;
/*
primary
secondary
tertiary
quaternary
quinary
senary
septenary
octonary
nonary
denary
duodenary
black
white
warning
*/

$colours: (primary:theme.$arcus-primary,
    secondary:theme.$arcus-secondary,
    tertiary:theme.$arcus-tertiary,
    quaternary:theme.$arcus-quaternary,
    quinary:theme.$arcus-quinary,
    senary:theme.$arcus-senary,
    septenary:theme.$arcus-septenary,
    octonary:theme.$arcus-octonary,
    nonary:theme.$arcus-nonary,
    denary:theme.$arcus-denary,
    duodenary:theme.$arcus-duodenary,
    basicblack:theme.$arcus-basicblack,
    basicwhite:theme.$arcus-basicwhite,
    warning:theme.$arcus-warning);
